import React from 'react'
import styled from 'styled-components'

// import './Button.css'

const PrimaryButton = styled.a `
    border: 2.6px solid #333333;
    display: inline-block;
    margin: 16px 24px 16px 0;
    border-color: ${props => props.onDark ? "#fff" : "#333"};
    transition: 0.2s cubic-bezier(0.2, 0.8, 0.2, 1);

    & p {
        color: ${props => props.onDark ? "#fff" : "#333"};
        font-weight: 700;
        font-size: 15px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        margin: 8px 20px;
        transition: 0.2s cubic-bezier(0.2, 0.8, 0.2, 1);
    }

    &:hover p {
    }
`

const Button = props => (
    <PrimaryButton onDark={props.onDark} href={props.link}>
        <p>{props.text}</p>
    </PrimaryButton>
)

export default Button





// const Button = props => (
//     <Link className="Button" to={props.link}>
//         <p>{props.text}</p>
//     </Link>
// )

